<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <div class="wrapper-left">
        <div class="wrapper-left-item">
          <div class="select-label">I want to spend</div>
          <div class="select-container">
            <a-input
              placeholder="00.00"
              :value="form.amount"
              size="large"
              type="number"
              @change="amountChange"
              :disabled="!selectDisabled"
            >
              <a-select
                class="spendClass"
                slot="addonAfter"
                :value="form.from_currency"
                @change="fromCurrencyChange"
              >
                <a-select-option
                  v-for="item of fromCurrencyOption"
                  :value="item.value"
                  :key="item.value"
                >
                  <img :src="item.logo" class="selectIcon" /> {{ item.label }}
                </a-select-option>
              </a-select>
            </a-input>
          </div>
        </div>
        <div class="wrapper-left-item">
          <div class="select-label">I want to buy</div>
          <div class="select-container">
            <!-- <input type="text" v-on:keyup.native="toCurrencyAmountOnkeyup" />
              v-on:keyup.native="toCurrencyAmountOnkeyup" -->
            <a-input
              :placeholder="buyCurrencyPlaceholder"
              :value="form.approximatelyAmount"
              size="large"
              type="number"
              :disabled="selectDisabled"
              @change="toCurrencyAmountChange"
            >
              <a-select
                slot="addonAfter"
                :value="form.to_currency"
                @change="toCurrencyChange"
                class="spendClass"
              >
                <a-select-option
                  v-for="item of toCurrencyOption"
                  :value="item.value"
                  :key="item.value"
                >
                  <img :src="item.logo" class="selectIcon" /> {{ item.label }}
                </a-select-option>
              </a-select>
            </a-input>
            <div class="rateTipsLeft" v-if="form.to_currency === 'NEO'">
              <img src="@/assets/jinggao.svg" alt="jinggao" />
              <span class="rateTipsTitle rateTipsTitleLeft">
                NEO is an indivisible currency. It means that it should
                represent a whole number.</span
              >
            </div>
          </div>
        </div>
        <div class="wrapper-left-item">
          <div class="select-label">Choose your Country</div>
          <div class="select-container">
            <a-select
              slot="addonAfter"
              @change="countryCodeChange"
              size="large"
              class="currencySelect"
              placeholder="Please select country of residence"
            >
              <a-select-option
                v-for="item of countryOption"
                :value="item.value"
                :key="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="wrapper-left-item wrapper-left-item-payment">
          <div class="select-label-weight">Payment method</div>
          <div class="select-container">
            <a-checkbox-group @change="paymentChange" class="paymentCheckbox">
              <a-checkbox value="visa" class="paymentCheckboxItem">
                Visa
              </a-checkbox>
              <a-checkbox value="mastercard" class="paymentCheckboxItem">
                Mastercard
              </a-checkbox>
            </a-checkbox-group>
          </div>
        </div>
      </div>
      <div class="wrapper-right">
        <div class="wrapper-right-item wrapper-right-router" v-if="showRouter">
          <div class="router-top">
            <div class="router-Title">Router</div>
            <div>
              <img
                src="@/assets/closeRight.png"
                alt="closeRight"
                @click="changeRouterChange"
              />
            </div>
          </div>
          <div class="router-center">
            <div class="router-center-container router-center-container-first">
              <div class="router-steps">
                <img src="@/assets/router_visa.png" alt="visa" />
                <img src="@/assets/router_masterCard.png" alt="visaMaster" />
              </div>
              <div class="router-steps">
                <img
                  class="rightJian"
                  src="@/assets/rightJian.png"
                  alt="rightJian"
                />
              </div>
              <div class="router-steps">
                <img
                  class="router-steps-img"
                  src="@/assets/USDT.png"
                  alt="USD"
                />
                <span>USDT(TRC20)</span>
              </div>
              <div class="router-steps">
                <img
                  class="rightJian"
                  src="@/assets/rightJian.png"
                  alt="rightJian"
                />
              </div>
            </div>
            <div class="router-center-container">
              <div class="router-steps">
                <img
                  class="router-steps-img"
                  src="@/assets/NEO.png"
                  alt="USD"
                />
                <span>NEO(N3)</span>
              </div>
              <div class="router-steps">
                <img
                  class="rightJian"
                  src="@/assets/rightJian.png"
                  alt="rightJian"
                />
              </div>
              <div class="router-steps">
                <img
                  class="router-steps-img"
                  src="@/assets/userWallet.png"
                  alt="USD"
                />
                <span>User Wallet</span>
              </div>
            </div>
          </div>
          <div class="router-bottom">
            <div class="router-bottom-title router-bottom-titleM">
              <span>Provider:</span>
              <img src="@/assets/banxaIcon2.png" alt="moonPay" />
              <span class="moonPayTitle">Banxa</span>
            </div>
            <div class="router-bottom-title">
              <span>Provider:</span>
              <img src="@/assets/logo.svg" alt="inst" />
            </div>
          </div>
        </div>
        <div class="loadingContainer" v-if="loading">
          <img class="loadingSty" src="@/assets/loading.svg" :alt="loading" />
        </div>
        <div
          class="wrapper-right-item"
          v-else
          v-for="item of rates"
          :key="item.channel_name"
        >
          <div class="wrapper-right-item-children">
            <div class="toCryAmount">
              {{ getApproximatelyAmount(item) }}
              {{ form.to_currency }}
            </div>
            <div class="toCryAmountTips">
              1 {{ form.to_currency }} ≈ {{ item.rate }}
              {{ form.from_currency }}
              <span>. All fees included</span>
            </div>
            <div class="paymentImg">
              <img
                class="paymentImgItem"
                v-for="itemChildren of item.payment_methods"
                :src="payCoinJson[itemChildren]"
                :alt="itemChildren"
                :key="itemChildren"
              />
            </div>
            <div class="paymentBuy">
              Powered by
              <img
                :src="coinJson[item.channel_name]"
                class="paymentBuy-icon"
              />{{ item.channel_name }}
              <img src="@/assets/score.svg" class="paymentBuy-icon" />{{
                item.score
              }}
            </div>
          </div>
          <div class="wrapper-right-item-children-buy">
            <a-button
              class="buyBtn"
              @click="buyNowBtn(item.channel_name, item.rate)"
            >
              Buy Now
            </a-button>
          </div>
        </div>
        <a-modal
          v-model="buyModelShowing"
          :maskClosable="false"
          centered
          :footer="null"
          @cancel="handleOnCancel"
        >
          <template slot="title" v-if="form.channel_name != 'inst'">
            <h3>
              Leaving Inst to
              {{ renderModalTitle() }}
            </h3>
          </template>
          <template slot="title" v-else>
            <h3>Enter your {{ form.to_currency }} wallet address</h3>
            <p class="titleTips">
              Our provider will send money to this address after you complete
              payment
            </p>
          </template>
          <div class="modalContainer">
            <div class="modalTips">
              {{ renderModalTitle() }} will transfer the crypto to your Account
              in 1~30mins,after you paying on {{ renderModalTitle() }}.
            </div>
            <div class="amountTipsContainer">
              <div class="amountTips">
                <span>Buy</span>
                <span class="amountValue">
                  {{ form.approximatelyAmount }} {{ form.to_currency }}
                </span>
              </div>
              <div class="amountTips">
                <span>Amount</span>
                <span class="amountValue">
                  {{ form.amount }} {{ form.from_currency }}
                </span>
              </div>
              <div class="amountTips">
                <span>Unit Price</span>
                <span class="amountValue">
                  ～ {{ unitPrice }} {{ form.from_currency }}
                </span>
              </div>
            </div>
            <div class="addressInput">
              <a-input
                :placeholder="`Enter your ${form.to_currency} recipient address`"
                :value="form.address"
                size="large"
                @change="addressChange"
              />
              <p class="addressTips" v-if="showAddressTips">
                Incorrect address format.Please check and try again.
              </p>
            </div>
            <div
              class="amountTips responsibilityContainer"
              v-if="form.channel_name != 'inst'"
            >
              <div class="checkboxContainer">
                <a-checkbox @change="checkBoxChange" :checked="checked" />
              </div>
              <div class="responsibilityTips">
                I Understand that
                {{ renderModalTitle() }} is a 3rd party service provider.Inst
                will not take any responsibility for any loss or damage caused
                by the use of this service.
                <div class="ruleTips" v-if="showRuleTips">Please Checked</div>
              </div>
            </div>
          </div>
          <a-button
            :class="goToBtnDisabled ? 'goToBtnDisabled' : 'goToBtn'"
            @click="submitGetOrder()"
            >Go to payment
          </a-button>
        </a-modal>
        <a-modal
          v-model="buyModelIframe"
          centered
          :footer="null"
          width="450"
          :maskClosable="false"
        >
          <iframe
            :src="redirect_url"
            id="iframe_moonPay"
            height="800"
            width="400"
            className="iframeStyle"
            frameBorder="no"
          >
            <p>您的浏览器暂不支持 iframe 标签。</p>
          </iframe>
        </a-modal>
        <a-modal
          v-model="buyModelIframeMer"
          centered
          :footer="null"
          :forceRender="true"
          width="450"
          :maskClosable="false"
        >
          <div id="mercuryo-widget" style="width: 315px; height: 800px" />
        </a-modal>
      </div>
    </div>
    <NewFooter />
    <form method="post" :action="this.form.actionUrl" ref="formRef">
      <input type="hidden" name="ac_ps" value="MASTERCARD" />
      <input type="hidden" name="ac_account_email" :value="this.form.email" />
      <input type="hidden" name="ac_sci_name" :value="this.form.ac_sci_name" />
      <input
        type="hidden"
        name="ac_amount"
        :value="this.form.amount"
        class="input"
      />
      <input
        type="hidden"
        name="ac_currency"
        :value="this.form.currency"
        class="input"
      />
      <input
        type="hidden"
        name="ac_order_id"
        :value="this.form.ac_order_id"
        class="input"
      />
      <input
        type="hidden"
        name="ac_sign"
        :value="this.form.ac_sign"
        class="input"
      />
      <input
        type="hidden"
        name="ac_success_url"
        :value="this.form.url"
        :disabled="true"
      />
      <input
        type="hidden"
        name="ac_fail_url"
        :value="this.form.url"
        :disabled="true"
      />
      <input
        type="hidden"
        name="ac_status_url"
        :value="this.form.url"
        :disabled="true"
      />
    </form>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
// eslint-disable-next-line import/extensions
import { getRates, buyCurrency } from '@/utils/api';
// eslint-disable-next-line camelcase
import country_list from '@/utils/Country.json';
import { authBefore, authAfter } from '@/utils/config';
// eslint-disable-next-line import/extensions
import { RegCoin, regNumber } from '@/utils/reg.js';
// eslint-disable-next-line import/extensions
import { fromCurrencyOption, toCurrencyOption } from '@/utils/coinImg.js';
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import advcashIcon from '@/assets/advcashIcon.svg';
import instIcon from '@/assets/instIcon.svg';
import mercuryoIcon from '@/assets/mercuryoIcon.png';
import mpIcon from '@/assets/mpIcon.svg';
import banxaIcon from '@/assets/banxaIcon2.png';
import payWayIcon from '@/assets/payWayIcon.png';
import visa from '@/assets/visa.svg';
import visaMaster from '@/assets/visaMaster.svg';
import applePay from '@/assets/ApplePay.svg';
import googlePay from '@/assets/GooglePay.svg';

export default {
  name: 'Home',
  components: { NavHeader, NavHeaderMobile, NewFooter },
  data() {
    return {
      form: {
        amount: '0',
        approximatelyAmount: '',
        from_currency: 'USD',
        to_currency: 'NEO',
        countryCode: '',
        paymentMethod: [],
        address: null,
        channel_name: '',
        url: window.location.href,
        actionUrl: 'https://wallet.advcash.com/sci/',
        email: '',
        ac_sci_name: '',
        currency: '',
        ac_order_id: '',
        ac_sign: '',
      },
      optimalRate: '',
      buyCurrencyPlaceholder: '00.00',
      selectDisabled: true,
      showRouter: true,
      showAddressTips: false,
      buyModelShowing: false,
      redirect_url: '',
      buyModelIframe: false,
      buyModelIframeMer: false,
      goToBtnDisabled: true,
      loading: false,
      showRuleTips: false,
      checked: false,
      getSignSuccess: false,
      rates: [],
      coinJson: {
        advcash: advcashIcon,
        moonpay: mpIcon,
        inst: instIcon,
        mercuryo: mercuryoIcon,
        banxa: banxaIcon,
        payway: payWayIcon,
      },
      payCoinJson: {
        visa,
        mastercard: visaMaster,
        applepay: applePay,
        googlepay: googlePay,
      },
      unitPrice: '',
      toCurrencyOption: [],
      fromCurrencyOption: [],
      countryOption: [],
    };
  },
  created() {
    const initCountry = [];
    // eslint-disable-next-line array-callback-return
    country_list.map((item) => {
      initCountry.push({ value: item.countryCode, label: item.name_en });
    });
    this.countryOption = initCountry;
    this.fromCurrencyOption = fromCurrencyOption;
    this.toCurrencyOption = toCurrencyOption;
    if (this.form.to_currency === 'NEO') {
      this.selectDisabled = false;
      this.form.approximatelyAmount = '1';
      this.buyCurrencyPlaceholder = '0';
    } else {
      this.selectDisabled = true;
      this.form.amount = '100.00';
      this.form.approximatelyAmount = '00.00';
    }
    this.initRate();
  },
  methods: {
    changeRouterChange() {
      this.showRouter = false;
    },
    randomString(e) {
      // eslint-disable-next-line no-param-reassign
      e = e || 32;
      const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      const a = t.length;
      let n = '';
      let i;
      // eslint-disable-next-line no-plusplus
      for (i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    getParams(url) {
      const params = {};
      const search = `?${url.split('?')[1]}`;
      if (search.indexOf('?') === 0 && search.indexOf('=') > 1) {
        const paramArray = unescape(search)
          .substring(1, search.length)
          .split('&');
        if (paramArray.length > 0) {
          paramArray.forEach((currentValue) => {
            // eslint-disable-next-line prefer-destructuring
            params[currentValue.split('=')[0]] = currentValue.split('=')[1];
          });
        }
      }
      return params;
    },
    initRate() {
      this.loading = true;
      const params = {
        from_currency: this.form.from_currency,
        to_crypto: this.form.to_currency,
        country: this.form.countryCode,
      };
      const authorization = `${authBefore}${Date.now()}${authAfter}`;
      if (this.form.paymentMethod.length) {
        params.payment_methods = this.form.paymentMethod.join(',');
      }
      getRates(params, authorization)
        .then((response) => {
          this.loading = false;
          const res = response.data;
          if (res.code === 0) {
            if (res.result.rates?.length) {
              this.rates = res.result.rates;
              const optimalRate = res.result.rates;
              let indexOfMax = 0;
              optimalRate.reduce(
                // eslint-disable-next-line no-return-assign
                (a, c, i) => (c.priority > a ? ((indexOfMax = i), c.priority) : a),
                0,
              );
              this.optimalRate = res.result.rates[indexOfMax].rate;
              this.form.amount = BigNumber(res.result.rates[indexOfMax].rate)
                .multipliedBy(this.form.approximatelyAmount)
                .toFixed(2);
            } else {
              this.form.amount = '';
              this.rates = [];
              this.$message.error('currency not support');
            }
          } else {
            this.$message.error(`${res.msg}`);
            this.rates = [];
          }
        })
        .catch(() => {
          this.loading = false;
          this.rates = [];
        });
    },
    getApproximatelyAmount(item) {
      if (this.form.to_currency === 'NEO') {
        return this.form.approximatelyAmount;
      }
      return `～ ${BigNumber(this.form.amount)
        .dividedBy(BigNumber(item.rate))
        .toFixed(4)}`;
    },
    amountChange(value) {
      this.form.amount = value.target.value;
      this.form.approximatelyAmount = `${BigNumber(value.target.value)
        .dividedBy(BigNumber(this.optimalRate))
        .toFixed(4)}`;
    },
    fromCurrencyChange(value) {
      this.form.from_currency = value;
      this.initRate();
    },
    toCurrencyChange(value) {
      if (value === 'NEO') {
        this.showRouter = true;
        this.selectDisabled = false;
      } else {
        this.showRouter = false;
        this.selectDisabled = true;
      }
      this.form.approximatelyAmount = 1;
      this.form.amount = '';
      this.form.to_currency = value;
      this.initRate();
    },
    toCurrencyAmountChange(value) {
      const validAmount = this.form.to_currency === 'NEO' && !regNumber.test(value.target.value);
      if (!validAmount) {
        this.form.approximatelyAmount = value.target.value;
        if (value.target.value) {
          this.form.amount = BigNumber(value.target.value)
            .multipliedBy(this.optimalRate)
            .toFixed(2);
        } else {
          this.form.amount = '0';
        }
      }
    },
    countryCodeChange(value) {
      this.form.countryCode = value;
      this.initRate();
    },
    paymentChange(value) {
      this.form.paymentMethod = value;
      this.initRate();
    },
    addressChange(value) {
      this.form.address = value.target.value;
      if (RegCoin[this.form.to_currency].test(value.target.value)) {
        this.showAddressTips = false;
        this.goToBtnDisabled = false;
      } else {
        this.showAddressTips = true;
        this.goToBtnDisabled = true;
      }
    },
    buyNowBtn(value, unitPrice) {
      this.buyModelShowing = true;
      this.form.channel_name = value;
      this.unitPrice = unitPrice;
      if (value === 'inst') {
        this.form.actionUrl = 'https://wallet.inst.money/sci/';
      } else {
        this.form.actionUrl = 'https://wallet.advcash.com/sci/';
      }
    },
    handleOnCancel() {
      this.form.address = null;
      this.showAddressTips = false;
      this.showRuleTips = false;
    },
    checkBoxChange() {
      this.checked = !this.checked;
      if (!this.checked) {
        this.showRuleTips = false;
      }
    },
    // eslint-disable-next-line consistent-return
    renderModalTitle() {
      // eslint-disable-next-line default-case
      switch (this.form.channel_name) {
        case 'advcash':
          return 'Advcash';
        case 'moonpay':
          return 'Moonpay';
        case 'inst':
          return 'Inst';
        case 'mercuryo':
          return 'Mercuryo';
        case 'airwallex_intent':
          return 'Airwallex';
        case 'airwallex':
          return 'Airwallex';
        default:
          return this.form.channel_name;
      }
    },
    async submitGetOrder() {
      if (!this.checked && this.form.channel_name !== 'inst') {
        this.showRuleTips = true;
      } else if (this.goToBtnDisabled) {
        //
      } else if (!this.form.amount) {
        this.$message.error('Please enter amount');
      } else {
        const param = {
          cust_order_id: this.randomString(10),
          amount: BigNumber(this.form.amount).toFixed(2),
          to_crypto: this.form.to_currency,
          from_currency: this.form.from_currency,
          channel_name: this.form.channel_name,
          authorization: `${authBefore}${Date.now()}${authAfter}`,
          address: this.form.address,
          return_url: window.location.href,
          rate: this.unitPrice,
          pay_with: 'card',
        };
        await buyCurrency(param)
          .then((response) => {
            this.buyModelShowing = false;
            const res = response.data;
            if (res.code === 0) {
              if (
                this.form.channel_name === 'advcash'
                || this.form.channel_name === 'inst'
                || this.form.channel_name === 'mercuryo'
              ) {
                this.form.amount = res.result.amount;
                this.form.currency = res.result.currency;
                this.form.email = res.result.data.email;
                this.form.ac_sci_name = res.result.data.sci_name;
                this.form.ac_order_id = res.result.order_id;
                this.form.ac_sign = res.result.data.signature;
                if (this.form.channel_name === 'inst') {
                  window.location.href = res.result.data.redirect_url;
                } else if (this.form.channel_name === 'mercuryo') {
                  const params = this.getParams(res.result?.data?.redirect_url);
                  this.buyModelIframeMer = true;
                  window.mercuryoWidget.run({
                    widgetId: params.widget_id,
                    host: document.getElementById('mercuryo-widget'),
                    address: params.address,
                    type: params.type,
                    currency: params.currency,
                    fixCurrency: params.fix_currency,
                    fixAmount: params.fix_amount,
                    fiatCurrency: params.fiat_currency,
                    fixFiatCurrency: params.fix_fiat_currency,
                    fiatAmount: params.fiat_amount,
                    fixFiatAmount: params.fix_fiat_amount,
                    merchantTransactionId: params.merchant_transaction_id,
                    signature: params.signature,
                  });
                } else if (this.form.channel_name === 'advcash') {
                  this.getSignSuccess = true;
                }
              } else {
                this.redirect_url = res.result.data.redirect_url;
                this.buyModelIframe = true;
              }
            } else {
              this.$message.error(`${res.msg}`);
            }
          })
          .catch((err) => {
            this.buyModelShowing = false;
            this.$message.error(`${err.response.data.msg}`);
          });
        if (this.getSignSuccess && this.form.channel_name === 'advcash') {
          this.$refs.formRef.submit();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1280px;
  display: flex;
  padding-top: 80px;
  margin: auto;
  margin-bottom: 40px;
  min-height: calc(100vh - 160px);
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;
  }
}
.wrapper-left {
  width: 360px;
  height: 540px;
  margin-top: 30px;
  padding: 20px;
  background: var(--background-common);
  margin-right: 40px;
  box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0.2);
  border-radius: 4px;
  @media (max-width: 960px) {
    margin: 30px auto 0;
  }
}
.wrapper-left-item {
  margin-bottom: 20px;
}
.wrapper-left-item-payment {
  margin-top: 40px;
}
.currencySelect {
  width: 100%;
}
.select-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.select-label-weight {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.paymentCheckbox {
  margin-top: 20px;
}
.paymentCheckboxItem {
  display: block;
  margin-left: 0px !important;
  margin-bottom: 20px;
}
.spendClass {
  width: 150px;
}
.selectIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 10px;
}
.wrapper-right {
  flex: 1;
  margin-top: 30px;
  width: 880px;
  @media (max-width: 960px) {
    width: 360px;
    margin: 30px auto 0;
  }
}
.wrapper-right-item {
  width: 880px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 960px) {
    width: 360px;
    flex-direction: column;
    .wrapper-right-item-children-buy {
      margin-top: 20px;
      width: 100%;
      .buyBtn {
        width: 100%;
      }
    }
  }
}
.wrapper-right-router {
  height: 122px;
  padding: 15px 30px;
  background-color: #1e253a;
  flex-direction: column;
  @media (max-width: 960px) {
    height: auto;
    padding: 15px 20px;
  }
  .router-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;
    .router-Title {
      font-size: 18px;
      color: #fff;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
    }
    img {
      cursor: pointer;
    }
  }
  .router-center {
    width: 100%;
    display: flex;
    flex: 1;
    .router-steps {
      height: 28px;
      margin-right: 30px;
      img {
        height: 24px;
        margin-right: 10px;
        margin-top: 4px;
      }
      .router-steps-img {
        margin-top: -4px;
      }
      .rightJian {
        height: 12px;
        @media (max-width: 960px) {
          margin-top: 8px;
        }
      }
      span {
        font-size: 14px;
        font-family: Poppins-Medium, Poppins;
        font-weight: 500;
        color: #ffffff;
      }
    }
    @media (max-width: 960px) {
      flex-direction: column;
    }
  }
  .router-center-container {
    display: flex;
    flex: 1;
    @media (max-width: 960px) {
      margin-bottom: 20px;
      justify-content: end;
      .router-steps {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        .router-steps-img {
          margin-top: 4px;
        }
      }
    }
  }
  .router-center-container-first {
    justify-content: end;
  }
  .router-bottom {
    display: flex;
    width: 100%;
    .router-bottom-title {
      display: flex;
      flex: 1;
      padding-right: 60px;
      span {
        display: inline-block;
        height: 20px;
        font-size: 12px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #ffffff;
        margin-right: 8px;
        opacity: 0.5;
      }
      .moonPayTitle {
        font-size: 12px;
        opacity: 1;
        margin-left: 4px;
      }
      img {
        height: 16px;
      }
    }
    .router-bottom-titleM {
      flex: 1;
      justify-content: right;
    }
  }
}
.toCryAmount {
  font-size: 20px;
  font-weight: bold;
}
.toCryAmountTips {
  font-size: 14px;
  opacity: 0.3;
  margin-bottom: 20px;
}
.paymentImg {
  margin-bottom: 10px;
}
.paymentImgItem {
  display: inline-block;
  width: 26px;
  margin-right: 10px;
}
.paymentBuy {
  font-size: 12px;
  font-weight: 400;
}
.paymentBuy-icon {
  height: 12px;
  margin: 0 10px;
}
.buyBtn {
  background: #0045ff;
  border-radius: 8px;
  width: 180px;
  height: 60px;
  color: #fff;
}
.titleTips {
  font-size: 12px;
  opacity: 0.5;
}
.modalContainer {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
}
.modalTips {
  padding-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
}
.amountTipsContainer {
  border-bottom: 1px solid #d8d8d8;
}
.amountTips {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.amountValue {
  color: black;
}
.addressInput {
  margin-top: 20px;
}
.addressTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 10px;
}
.rateTipsLeft {
  color: #000;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  background: rgba(230, 154, 58, 0.12);
  display: flex;
  border-radius: 5px;
  padding: 10px;
  img {
    width: 24px;
    height: 24px;
  }
  .rateTipsTitleLeft {
    opacity: 0.8;
    margin-left: 4px;
  }
}
.checkboxContainer {
  display: flex;
}
.responsibilityContainer {
  padding: 20px 0px;
}
.responsibilityTips {
  font-size: 12px;
  margin-left: 20px;
}
.ruleTips {
  margin-top: 10px;
  color: #c91919;
}
.goToBtn {
  background: #0045ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 20px;
}
.goToBtnDisabled {
  background: #80a2ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 20px;
}
.loadingContainer {
  height: 200px;
  line-height: 200px;
  text-align: center;
}
.loadingSty {
  margin: auto;
  display: inline-block;
  height: 48px;
  animation: animal 1s infinite linear;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}
@keyframes animal {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.select-container {
  /deep/.ant-select-selection {
    border-radius: 15px;
  }
  /deep/.ant-input-group {
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    .ant-input {
      border: 0;
      border-radius: 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-weight: bold;
    }
  }
  //input去除加减号样式
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
  /deep/.ant-input-group:hover {
    border: 1px solid #0045ff;
    box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
    transition: all 0.3s;
  }
  /deep/.ant-input-group:focus-within {
    border: 1px solid #0045ff;
    box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
    transition: all 0.3s;
  }
  /deep/.ant-input:hover {
    border: none;
  }
  /deep/.ant-input:focus {
    border: none;
    box-shadow: none;
    // box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
  }
  /deep/.ant-input-group-addon {
    border: none;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
  }
  /deep/.ant-select-selection-selected-value {
    font-weight: bold;
  }
}
</style>
